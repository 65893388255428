.input_with_icon {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 48px;
  background-color: #202028;
  border-radius: 4px;
  transition: border 0.2s ease;
  // overflow: hidden;
  border: 1px solid transparent;
  transition: 0.2s ease-out;

  &:hover {
    background-color: #363744;
  }
}

.error_message {
   position: absolute;
   color: #eb4b4b;
   font-size: 14px;
   right: 0;
   bottom: calc(100% + 8px);
   z-index: 1;
 }

.input_error {
  border-color: #eb4b4b !important;
}

.input_icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 100%;
  flex-shrink: 0;

  &_right {
    position: absolute;
    right: 0;
  }
}

.input_field {
  width: 100%;
  height: 100%;
  border: none;
  background: transparent;
  color: #ffffff;
  font-size: 16px;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: #b3b5c6;
    opacity: 1;
  }
}

.required_star {
  position: absolute;
  right: 12px;
  color: #eb4b4b;
  font-size: 18px;
}

.promo_badge {
  position: absolute;
  right: 12px;
  color: #ffb800;
  font-size: 16px;
  font-weight: 700;
}
