.footer {
  margin: 0 auto;

  &_top {
    display: flex;
    gap: 20px;
    padding-bottom: 24px;
    border-bottom: 2px solid #202028;
    margin-bottom: 24px;

    & > :first-child {
      flex-grow: 1;
      display: flex;
      gap: 20px;
      flex-direction: column;
    }

    @media (max-width: 992px) {
      flex-direction: column;
    }

    @media (max-width: 767px) {
      gap: 10px;
    }
  }
  &_top_item {
    width: 246px;

    @media (max-width: 992px) {
      width: 100%;
    }
  }
  &_top_item_menu {
    display: flex;
    flex-direction: column;
    gap: 16px;

    @media (max-width: 767px) {
      gap: 8px;
    }
  }
  &_top_item_title {
    font-weight: 500;
    font-size: 16px;
    line-height: 1.25;
    color: #fff;

    svg {
      display: none;
    }

    @media (max-width: 767px) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      border-radius: 4px;

      svg {
        display: inline;
      }
    }
  }
  &_top_item_title_active {
    @media (max-width: 767px) {
      color: #ffb800;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
  &_top_item_list {
    display: flex;
    flex-direction: column;
    gap: 16px;

    @media (max-width: 767px) {
      display: none;
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.3s ease-out;
      gap: 8px;
    }

    a {
      font-size: 14px;
      line-height: 1.4;
      color: #6c6f8b;
      text-decoration: none;

      @media (max-width: 767px) {
        width: calc(50% - 8px);
      }

      &:hover {
        color: #fff;
      }
    }
  }
  &_top_item_list_open {
    @media (max-width: 767px) {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      max-height: 500px;
      transition: max-height 0.5s ease-in;
      padding: 0;
      margin: 0;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
  &_dropdown {
    @media (max-width: 767px) {
      border-bottom: none;
    }
  }
  &_dropdown_arrow {
    @media (max-width: 767px) {
      transition: transform 0.3s ease;
      color: #fff;
    }
  }
  &_dropdown_arrow_open {
    @media (max-width: 767px) {
      transform: rotate(180deg);
      color: #fff;
    }
  }
  &_copyright {
    font-weight: 300;
    font-size: 12px;
    line-height: 1.4;

    @media (max-width: 992px) {
      width: 100%;
    }
  }
  &_mail_link {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 20px;

    a {
      display: flex;
      align-items: center;
      gap: 8px;
      font-weight: 400;
      font-size: 16px;
      line-height: 1.25;
      color: #fff;
      text-decoration: none;

      &:hover {
        color: #ffb800;
      }
    }
  }
  &_link_content_descr {
    font-weight: 400;
    font-size: 12px;
    color: #47485d;
  }
  &_social {
    display: flex;
    gap: 16px;

    a {
      color: #363744;

      &:hover {
        color: #d6f0fc;
      }
      &:active {
        color: #49c0f4;
      }

      &.discord {
        &:hover {
          color: #d6d3ff;
        }
        &:active {
          color: #9378ff;
        }
      }
      &.twitch {
        &:hover {
          color: #dec9ff;
        }
        &:active {
          color: #b684ff;
        }
      }
      &.x {
        &:hover {
          color: #d6f0fc;
        }
        &:active {
          color: #1d9bf0;
        }
      }
      &.tiktok {
        &:hover {
          color: #d3d3f6;
        }
        &:active {
          color: #6767f6;
        }
      }
      &.youtube {
        &:hover {
          color: #ffd3d3;
        }
        &:active {
          color: #f63e3e;
        }
      }
    }
  }
  &_bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 40px;

    @media (max-width: 992px) {
      flex-direction: column;
      gap: 40px;
      align-items: flex-start;
    }
  }
  &_bottom_pays {
    display: flex;
    gap: 8px;
    max-width: 100%;

    @media (max-width: 640px) {
      img {
        width: 76px;
        height: auto;
      }
    }
  }
  &_bottom_other {
    display: flex;
    gap: 8px;
  }
  &_bottom_other_item {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  &_logo_wrap {
    display: flex;
    flex-direction: column;

    @media (max-width: 992px) {
      flex-direction: row-reverse;
    }
  }
}
