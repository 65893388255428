.container {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 1600px;
  width: 100%;

  &_row {
    flex-direction: row;
  }

  @media(max-width: 1600px) {
    padding: 0 24px;
  }
  @media(max-width: 992px) {
    padding: 0 16px;
  }
}