.btn1 {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  padding: 10px;
  border-radius: 4px;
  background: #363744;
  color: #fff;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.5;
  cursor: pointer;
  text-decoration: none;
  transition: 0.2s ease-out;
  text-align: center;
  justify-content: center;

  @media (max-width: 1024px) {
    padding: 4px 8px;
  }
  &:hover {
    background: #3d3e4f;
  }
  &:active {
    background: #202028;
  }

  // Стили для иконочной кнопки
  &_icon {
    width: 32px;
    height: 32px;
    padding: 0;
    justify-content: center;
  }

  &_yellow {
    background: #ffb800;
    color: #482000;

    &:hover {
      background: #ffd81b;
    }
    &:active {
      background: #e28d00;
    }

    &_bordered {
      border: 2px solid #ffb800;
      background: transparent;
      color: #fff;

      &:hover {
        border-color: #ffd81b;
        background: transparent;
      }
      &:active {
        border-color: #e28d00;
        background: transparent;
      }
    }
  }

  &_blue {
    background: #3957aa;
    color: #fff;

    &:hover {
      background: #4770c9;
    }
    &:active {
      background: #374d96;
    }

    &_bordered {
      border: 2px solid #3957aa;
      background: transparent;
      color: #fff;
      padding: 8px 10px;

      &:hover {
        border-color: #4770c9;
        background: transparent;
      }
      &:active {
        border-color: #374d96;
        background: transparent;
      }
    }
  }

  &_blue_light {
    background: #1c63f4;
    color: #fff;

    &:hover {
      background: #154de0;
    }
    &:active {
      background: #1943c1;
    }

    &_bordered {
      border: 2px solid #1c63f4;
      background: transparent;
      color: #fff;

      &:hover {
        border-color: #154de0;
        background: transparent;
      }
      &:active {
        border-color: #1943c1;
        background: transparent;
      }
    }
  }

  &_green {
    background: #089408;
    color: #ffffff;

    &:hover {
      background: #0c6f0d;
    }
    &:active {
      background: #003302;
    }
  }

  &_dark {
    background: #202028;
    color: #ffffff;

    &:hover {
      background: #363744;
    }
    &:active {
      background: #131318;
    }

    &_bordered {
      border: 2px solid #202028;
      background: transparent;
      color: #ffffff;

      &:hover {
        border-color: #363744;
        background: transparent;
      }
      &:active {
        border-color: #131318;
        background: transparent;
      }
    }
  }

  &_tab_bordered {
    background-color: transparent;
    border: 2px solid #363744;
    color: #ffffff;
    padding: 10px 12px;
    min-width: 48px;
    justify-content: center;

    &:hover {
      border-color: #3d3e4f;
      background-color: transparent;
    }

    &:active {
      border-color: #202028;
    }

    &_selected {
      border-color: #3957aa;
      pointer-events: none;
    }
  }

  &_disabled {
    opacity: 0.48;
    pointer-events: none;
  }
}
